:root {
  --green: #599854;
  --shadowGray: #ececdd;
  --lighterGray: #e2e3e4;
  --canvasYellow: #fffce5;
  --lightGray: #d1d4d9;
  --darkGray: #898989;
  --darkGrayTransparent: rgba(119, 125, 129, 0.567);

  --colorBlack: #000000;
  --colorBlue: #0000FF;
  --colorRed: #FF0000;
  --colorGreen: #008000;
  --colorYellow: #DDCB00;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}


.Header {
  display: flex;
  font-size: 32px;
  padding: 4px 6px 8px;
  font-family: 'Alfa Slab One', Tahoma, sans-serif;
  border-bottom: 1px solid var(--lightGray);
}

.HeaderText {
  flex: 1;
}

.DoodlePage {
  margin: 24px auto 0px;
  width: 300px;
}

.DoodleCanvasEdit {
  touch-action: pinch-zoom;
  box-shadow: 0 0 4px 1px var(--shadowGray);
  border-radius: 1px;
  background-color: var(--canvasYellow);
  width: 100%;
}

.DoodleCanvas {
  touch-action: auto;
  box-shadow: 0 0 4px 1px var(--shadowGray);
  border-radius: 1px;
  background-color: var(--canvasYellow);
  width: 100%;
}

.HelpTitle {
  font-family: 'Alfa Slab One', Tahoma, sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 22px 4px 4px;
}

.ColorSelectors {
  display: inline-block;
  margin: 0 12px;
}

.ColorSelector {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0 3px 4px;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  line-height: 24px;
}

.ColorSelected {
  border: 2px solid var(--lightGray);
  box-sizing: border-box;
  box-shadow: 0 0 3px 2px var(--lighterGray);
}

.ColorBlack {
  background-color: var(--colorBlack);
}

.ColorBlue {
  background-color: var(--colorBlue);
}

.ColorRed {
  background-color: var(--colorRed);
}

.ColorGreen {
  background-color: var(--colorGreen);
}

.ColorYellow {
  background-color: var(--colorYellow);
}


.SettingsButton {
  background-color: var(--lighterGray);
  color: black;
  height: 34px;
  font-size: 15px;
  padding: 0 8px;
  border: none;
  border-radius: 4px;
  margin: 6px 2px;
}

.GreenButton {
  background-color: var(--green);
}

.SettingsButton:disabled {
  background-color: var(--lighterGray);
  color: var(--darkGrayTransparent);
  height: 34px;
  padding: 0 8px;
  border: none;
}

.SettingsFooter {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  column-gap: 12px;
}

.ZoomText {
  font-weight: 600;
}

.HelpText {
  font-size: 16px;
  margin: 8px 0 16px;
}

.FooterText {
  padding: 18px;
  color: var(--darkGray);
  font-size: 14px;
}

.FooterText a {
  color: var(--darkGray);
  margin-left: 6px;
}
